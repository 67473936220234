<template>
    <div class="cadre">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
      name: 'Suivi'
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    
    .suivi-choix {
        display: flex;

        div {
            padding: 10px;
            cursor: pointer;
            border: 1px solid $primary;
            font-size: .75rem;
            min-width: 100px;
            text-align: center;

            &.router-link-active {
                background: $primary;
                color: white;
            }
        }
    }
</style>
