import Vue from 'vue';
import dayjs from 'dayjs'

Vue.filter('date', function (value) {
  if (value) {
    return dayjs(String(value)).format('DD/MM/YYYY')
  }
});

Vue.filter('dateDuree', function (value) {
  const matches = /(.*)\/(.*)\/(.*)/gm.exec(value);
  const date = dayjs(`${matches[3]}-${matches[2]}-${matches[1]}`)
  return dayjs().diff(date, 'day');
});
