import axios from 'axios'

class Api {

  constructor(url) {
    this.axios = axios.create({
      baseURL: url,
      transformResponse: [function (data, headers) {
        if(headers['content-type'] === 'application/json'){
          const fn = JSON.parse || JSON.decode
          return fn(data);
        }
        return data
      }],
    })
  }

  configurer(token, cb) {
    this.axios.defaults.headers.common['jeton'] = token || process.env.VUE_APP_DEFAULT_TOKEN;

    this.axios.interceptors.response.use((response) => {
      return response
    }, (err) => {
      if (err.response.status === 403) {
        cb()
      }
      return Promise.reject(err)
    })
  }

  dossiersCharger() {
    return this.axios.get(`/dossiers`)
  }

  compteCharger() {
    return this.axios.get('/utilisateurs/compte')
  }

  suiviParIndividuCharger(query) {
    return this.axios.get('/suivi/export/collaborateurs', { params: query })
  }

  tableauBordCharger(query) {
    return this.axios.get(`/suivi/tableau-de-bord`, { params: query })
  }

  dossiersEncaissesCharger(query) {
    return this.axios.get(`/suivi/export/encaisses`, { params: query })
  }

  dossiersFacturesCharger(query) {
    return this.axios.get(`/suivi/export/factures`, { params: query })
  }

  dossiersPrevisionnelsCharger(query) {
    return this.axios.get(`/suivi/export/previsionnels`, { params: query })
  }

  dossiersEnAlerteCharger(query) {
    return this.axios.get(`/suivi/export/alertes`, { params: query })
  }

  dossierEtapesCharger(dossierId) {
    return new Promise((res) => {
      res([
        {id: 1, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 2, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 3, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 4, libelle: 'Notice et mandat signés', etat_id: 2},
        {id: 5, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 6, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 7, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 8, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 9, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 10, libelle: 'Notice et mandat signés', etat_id: 1},
        {id: 11, libelle: 'Notice et mandat signés', etat_id: 1},
      ])
    });
  }

  dossiersEnCoursCharger(query) {
    return this.axios.get(`/suivi/export/potentiels`, { params: query })
  }

  dossiersEnCoursGraphiqueCharger() {
    return this.axios.get('/suivi/graphique/en-cours')
  }


  intervenantsCharger(query) {
    return this.axios.get(`/utilisateurs/compte/entites`)
  }

  jetonRecuperer(){
    return this.axios.get('/utilisateurs/compte/jeton')
  }

  apporteurBilan(query){
    return this.axios.get('/repartition/apporteur/bilan', { params: query })
  }

  banqueBilan(query){
    return this.axios.get('/repartition/banque/bilan', { params: query })
  }

  get_liste_apporteur(query){
    return this.axios.get('/repartition/apporteur/listInterlocuteurs', { params: query })
  }

  get_liste_banque(query){
      return this.axios.get('/repartition/banque/listInterlocuteurs', { params: query })
  }

  get_liste_mandataire(query){
    return this.axios.get('/repartition/listMandataires', { params: query })
  }

  apporteurInterlocuteur(query){
    return this.axios.get('/repartition/apporteur/interlocuteur', { params: query })
  }

  banqueInterlocuteur(query){
    return this.axios.get('/repartition/banque/interlocuteur', { params: query })
  }
}

const locationName = `${window.location.protocol}//${window.location.href.split('/')[2]}/api/v1/`
const apiUrl = (process.env.VUE_APP_API_URL) ? process.env.VUE_APP_API_URL : locationName

export default new Api(apiUrl)
