<template>
    <div>
        <router-link
            class="lien-retour"
            :to="{name: 'TableauDeBord', query: {dateDebut : this.dateDebut, dateFin : this.dateFin}}"
        >
            <i class="fas fa-chevron-left"></i> Retour au tableau de bord
        </router-link>
        <div class="cadre">
            <div class="cadre-tete cadre-tete--menu">
                DOSSIERS ENCAISSÉS

                <filters
                    api="dossiersEncaissesCharger"
                    :filtrer="filtrer"
                    :actions="['intervenant', 'export']"
                    :pagination="true"
                    :defaultData="{intervenant, dateDebut, dateFin}"
                >

                </filters>
            </div>
            <div class="cadre-content">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Intervenant</th>
                        <th>Dossier</th>
                        <th>Banque</th>
                        <th>Apporteur</th>
                        <th>Date réglement</th>
                        <th>N° facture</th>
                        <th class="number">Montant brut</th>
                        <th class="number">Montant net</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="row"
                        v-for="(row, idx) in rows"
                        :key="idx"
                    >
                        <td>{{row.intervenant}}</td>
                        <td>
                            <div>{{row.client_lib}}</div>
                            <small>{{row.dossier_numero}}</small>
                        </td>
                        <td>
                            <div>{{row.banque_agence}}</div>
                            <small>{{row.banque_groupe}}</small>
                        </td>
                        <td>
                            <div>{{row.apporteur}}</div>
                            <small>{{row.apporteur_contact}}</small>
                        </td>
                        <td>{{row.reglement_date}}</td>
                        <td>{{row.facture_numero}}</td>
                        <td class="number">{{row.facture_brut}} {{$compte.societe_devise}}</td>
                        <td class="number">{{row.facture_net}} {{$compte.societe_devise}}</td>
                        <td class="center">
                            <i
                                class="fas fa-folder fa-2x"
                                @click="dossierCliquer(row.id)"
                            ></i>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="pagination-container">
                    <paginate
                        v-model="page"
                        :page-count="Math.ceil(total / limite)"
                        :click-handler="clickPage"
                        :prev-text="'&#10094;'"
                        :next-text="'&#10095;'"
                        :container-class="'pagination'">
                    </paginate>
                </div>
            </div>
        </div>

        <div class="chargement" v-if="chargement">
            <div>
                <b> Chargement en cours... </b>
            </div>
        </div>
    </div>


</template>

<script>
  import api from '@/api'
  import dayjs from 'dayjs'

  export default {
    name: 'DossiersEncaisses',
    data() {
      return {
        lignesOuvertes: [],
        etapes: {},
        rows: [],
        total: 0,
        limite: 10,
        page: 1,
        dateDebut: dayjs().startOf('year').format('YYYY-MM-DD'),
        dateFin: dayjs().endOf('year').format('YYYY-MM-DD'),
        intervenant: null,
      }
    },
    created() {

      const {intervenant, dateDebut, dateFin} =  this.$route.query || {}
      this.intervenant = intervenant
      this.dateDebut = dateDebut ? dateDebut : this.dateDebut
      this.dateFin = dateFin ? dateFin : this.dateFin
      this.charger()
    },
    methods: {
      dossierCliquer(dossierId) {
        const e = new CustomEvent('dossierCharger', {detail: {dossierId}})
        window.dispatchEvent(e)
      },
      clickPage(page) {
        this.page = page
        this.charger()
      },
      charger(){
        this.chargement = true

        api.dossiersEncaissesCharger({
          page: this.page,
          limite: this.limite,
          debut: this.dateDebut,
          fin: this.dateFin,
          intervenant: this.intervenant
        }).then((d) => {
          const {data, total} = d.data
          this.rows = data
          this.total = total
          this.chargement = false
        })
      },
      filtrer(data) {
        this.rows = data.data
        this.total = data.total
        this.dateDebut = data.debut
        this.dateFin = data.fin
        this.intervenant = data.intervenant
        this.page = 1
      }
    }
  }
</script>

<style lang="scss" scoped>

    @import '@/assets/scss/_colors.scss';

    .cadre {
        height: auto !important;
    }

    .cadre-content {
        position: relative;
    }

    .row-expand-content {
        border-bottom: 1px solid #E7E7E7;
        > div {
            padding: 10px;
            display: flex;
            overflow-x: auto;
            width: 100%;
        }

        .etape {
            padding: 10px;
            width: 100px;
            height: 100px;
            background: #ba3b35;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .lien-retour {
        text-decoration: none;
        color: $gray;
        display: block;
        padding: 10px;
    }

    .pagination-container {
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .table {
        font-size: .75rem;
    }
</style>
