<template>
    <div class="conteneur background-gray">
        <div class="navigation cadre-left" >
            <div class="cadre-conteneur">
                <div id="menu-titre">
                    <div id="menu-reference"> Statistiques </div>
                    <div id="menu-label"> Répartitions des dossiers </div>
                </div>
                <div class="cadre-content">
                    <ul id ="menu">
                        <li>
                            <router-link
                                :to="{name: 'MenuBanque'}"
                            >
                               Banques
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{name: 'MenuApporteur'}"
                            >
                                Apporteurs
                            </router-link> 
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div 
            class="conteneur-vues"
            >
            <router-view></router-view>
        </div>

        <div class="chargement" v-if="chargement.afficher">
            <div>
                <b> {{chargement.texte}} </b>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'MenuRepartitionDossiers',
    beforeRouteEnter(to, from, next) {
      if (process.env.NODE_ENV === 'development') {
        api.configurer(null)
        next()
        return
      }
      
      api.jetonRecuperer()
        .then(({data}) => {
          api.configurer(data.jeton)
          next()
        })
    },
    data() {
      return {
          isActiveBanque: true,
          isActiveApporteur: false,
          chargement: {
              afficher: false,
              texte: 'Chargement en cours...'
          }
      }
    },
    created() {
        this.$root.$on('chargementAfficher', (show) => {
            this.chargement.afficher = show
        })
    },
  }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    
    .conteneur {
        width: calc(100vw);
        height: 100vh;
        display: flex;
        border-top: 1px solid #ddd;
        /* Triche pour faire sauter le padding des tables */
        margin-left: -5px;
    }

    .conteneur-vues {
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        border-left: 1px solid #ddd;
        background: #f9f9f9;
    }

    .cadre-left {
        min-width: 220px;
        max-width: 220px;
        display: flex;
        flex-direction: column;
        height: 100%;
        font-size: 15px;
    }

    #menu-titre {
        border-bottom: 1px solid #ddd;
        background: #fff;

        #menu-reference {
            padding: 12px 0 2px 20px;
            font-weight: 600;
            font-size: 0.9rem;
        }

        #menu-label {
            padding: 0 0 10px 20px;
            color: #105BFB;
            font-weight: 500;
        }
    }

    #menu {
        padding: 0;
        font-size: 16px;
        margin: 0;

        a {
            font-weight: 500;
            display: block;
            position: relative;
            padding: 20px;
            border-bottom: 1px solid #ddd;
            color: #555;
            text-decoration: none;
            left: 0 !important;
        }

        a.router-link-active,
        a:hover {
            background: #f4f4f4;
            color: $primary;
        }
    }

    .modal-container .cadre {
        height: calc(100vh - 180px) !important;
    }

</style>
