import 'custom-event-polyfill/polyfill'
import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import router from '@/router'
import App from './App.vue'

//import { Vuetable, VueTablePagination, VueTablePaginationDropDown, VueTablePaginationInfo } from 'vuetable-2';
import Vuetable from 'vuetable-2'
Vue.component("vuetable", Vuetable);

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import { Compte } from '@/utils'
Vue.use(Compte);

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue);

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import Modal from '@/components/Modal.vue'
Vue.component('modal', Modal)

import Filters from '@/components/Filters'
Vue.component('filters', Filters)
import '@/filters';

import pace from 'pace-js-amd-fix/pace'

pace.start();

Vue.config.productionTip = false;

import api from '@/api'

window.repartitionVueApp = function() {
  return new Vue({
    router,
    render: h => h(App),
    beforeCreate() {
      this.$router.push({name: 'MenuBanque'})
    },
    mounted: function () {
      /* Désactivation du scroll sur le contexte Historique : .window_container
         Incapacité à trouver un moyen d'ajouter une class à un link maker / window dans le contexte Historique.
      */
      var m = document.getElementsByClassName('.courtisia-module')[0];
      var w = m.closest('.window_container');
      w.style.overflow = "hidden";
    }
  });
}

window.suiviVueApp = function() {
  return new Vue({
    router,
    render: h => h(App),
    beforeCreate() {
      if (process.env.NODE_ENV !== 'development') {
        this.$router.push({name: 'TableauDeBord'})
      }
    }
  });
}

if (document.querySelector('.vuejs_app')) {
  window.suiviVueApp().$mount('.vuejs_app');
}


