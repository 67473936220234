<template>
    <div id="app">
        <canvas id="myChart" ref="myChart"></canvas>
    </div>
</template>

<script>
    import Chart from 'chart.js';

    export default {
        props:{
            data: Array,
            labels: Array,
        },
        mounted () {

            const ctx = this.$refs.myChart.getContext('2d');

            if (this.data.length === 2) {
                const chart = new Chart(ctx, {
                    // The type of chart we want to create
                    type: 'bar',
                    // The data for our dataset
                    data: {
                        labels: this.labels,
                        datasets: [{
                            label: 'Nombre de Dossiers apportés',
                            backgroundColor: "#1EAAF1",
                            data: this.data[0]
                        },
                            {
                                label: 'Nombre de Dossiers finalisés (en Signature acte)',
                                backgroundColor: '#6798ff',
                                data: this.data[1]
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false
                    }
                });
            } else if (this.data.length === 3) {
                const chart = new Chart(ctx, {
                    // The type of chart we want to create
                    type: 'bar',
                    // The data for our dataset
                    data: {
                        labels: this.labels,
                        datasets: [{
                                label: 'Nombre de Dossiers envoyés',
                                backgroundColor: "#1EDBF1",
                                data: this.data[0]},
                            {
                                label: 'Nombre de Dossiers avec une banque prêteuse',
                                backgroundColor: "#1EAAF1",
                                data: this.data[1]},
                            {
                                label: 'Nombre de Dossiers finalisés (en Signature acte)',
                                backgroundColor: '#6798ff',
                                data: this.data[2]
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false
                    }
                });
            }
        },

    }




</script>

<style scoped>
    #app{
        background: #fff;
        border: 1px solid #E7E7E7;
        border-radius: 3px;
        position: relative;
        width: calc(100% - 60px);
        height: 50vh;
        margin: 15px;
        padding: 15px;
    }
</style>