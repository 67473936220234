import VueRouter from 'vue-router'
import TableauDeBord from '@/views/TableauDeBord'
//import ChoixSuivi from '@/views/ChoixSuivi'
import DossiersEncaisses from '@/views/DossiersEncaisses'
import DossiersFactures from '@/views/DossiersFactures'
import DossiersEnCours from '@/views/DossiersEnCours'
import DossiersEnAlerte from '@/views/DossiersEnAlerte'
import DossiersPrevisionnels from '@/views/DossiersPrevisionnels'
import Suivi from '@/views/Suivi'
import SuiviAgence from '@/views/SuiviAgence'
import SuiviIndividu from '@/views/SuiviIndividu'
import Main from '@/views/Main'
import Menu from "../views/repartition/Menu";
import MenuBanque from "../views/repartition/banque/Menu";
import BilanBanque from "../views/repartition/banque/Bilan";
import InterlocuteurBanque from "../views/repartition/banque/Interlocuteur";
import MenuApporteur from "../views/repartition/apporteur/Menu";
import BilanApporteur from "../views/repartition/apporteur/Bilan";
import InterlocuteurApporteur from "../views/repartition/apporteur/Interlocuteur";

const routes = [
  {
    path: '/',
    component: Main,
    redirect: { name: 'TableauDeBord' },
    children: [
      {
        path: 'tableau-de-bord',
        name: 'TableauDeBord',
        params: {
          dateDebut : null,
          dateFin : null
        },
        component: TableauDeBord,
      },
      /*{
        path: '/choix-suivi',
        name: 'ChoixSuivi',
        component: ChoixSuivi
      },*/
      {
        path: 'dossiers-encaisses',
        name: 'DossiersEncaisses',
        component: DossiersEncaisses
      },
      {
        path: 'dossiers-factures',
        name: 'DossiersFactures',
        component: DossiersFactures
      },
      {
        path: 'dossiers-en-cours',
        name: 'DossiersEnCours',
        component: DossiersEnCours
      },
      {
        path: 'dossiers-en-alerte',
        name: 'DossiersEnAlerte',
        component: DossiersEnAlerte
      },
      {
        path: 'dossiers-previsionnels',
        name: 'DossiersPrevisionnels',
        component: DossiersPrevisionnels
      },
      {
        path: 'suivi',
        name: 'Suivi',
        component: Suivi,
        children: [
          {
            path: 'agence',
            name: 'SuiviAgence',
            component: SuiviAgence
          },
          {
            path: 'individu',
            name: 'SuiviIndividu',
            component: SuiviIndividu
          }
        ]
      },
    ]
  },
  {
    path: '/repartion-des-dossiers',
    component: Menu,
    redirect: { name: 'MenuBanque' },
    children: [
      {
        path: '/banque',
        name: 'MenuBanque',
        component: MenuBanque,
        redirect: { name: 'BilanBanque' },
        children: [
          {
            path: 'bilan',
            name: 'BilanBanque',
            component: BilanBanque,
          },
          {
            path: 'interlocuteur',
            name: 'InterlocuteurBanque',
            component: InterlocuteurBanque,
          }
        ]
      },
      {
        path: '/apporteur',
        name: 'MenuApporteur',
        component: MenuApporteur,
        redirect: { name: 'BilanApporteur' },
        children: [
          {
            path: 'bilan',
            name: 'BilanApporteur',
            component: BilanApporteur,
          },
          {
            path: 'interlocuteur',
            name: 'InterlocuteurApporteur',
            component: InterlocuteurApporteur,
          }
        ]
      }
    ]
  }
]

export default new VueRouter({
  routes // short for `routes: routes`
})
