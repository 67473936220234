<template>
    <div class="cadre-content">
        <ul class="navbar">
            <li>
                <router-link
                        :to="{name: 'BilanApporteur'}"
                        class="selecteur_menu"
                >
                     Mon Bilan Par Mois
                </router-link>
            </li>
            <li>
                <router-link
                        :to="{name: 'InterlocuteurApporteur'}"
                        class="selecteur_menu"
                >
                     Mes Principaux Apporteurs
                </router-link>
            </li>
        </ul>
        <router-view></router-view>
    </div>
</template>

<script>
  export default {
    name: 'TableauDeBord',
    data() {
      return {
      }
    },
    created() {

    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
    

    .navbar a {
        left: 0 !important;
    }
   
</style>
