<template>
    <div class="courtisia-module suivi-container">
        <router-view></router-view>
    </div>
</template>

<script>

  import api from '@/api'

  export default {
    name: 'app',
  }
</script>

<style lang="scss">
    @import "assets/scss/main";
</style>
