<template>
    <div class="cadre background-gray">
        <div class="cadre-content">
            <div class="entete">
                <h1>Bonjour {{$compte.prenom}} {{$compte.nom}}</h1>
                <p>Suivez vos performances en un clin d'œil grâce aux chiffres clés des tableaux de suivi.</p>
                <p>
                    Chaque étape de la vie d'un dossier est accompagnée d'une vue détaillée vous permettant de personnaliser l'affichage de vos résultats.
                </p>
                <div class="entete-actions">
                    <router-link
                        tag="button"
                        class="btn btn-default intervenants"
                        :to="{ name: 'SuiviIndividu' }"
                        v-if="$compte.intervenants_visibilite"
                    >
                        <i class="fas fa-users"></i> Collaborateurs
                    </router-link>
                    <filters
                        class="filters"
                        :filtrer="filtrer"
                        :api="'tableauBordCharger'"
                        :actions="[]"
                        :facturesIndependantes="true"
                        :defaultData="{dateDebut: this.dateDebut, dateFin: this.dateFin}"
                    ></filters>
                </div>

            </div>

            <div class="suivis-container">
                <div class="cadre background-white">
                    <div class="cadre-tete cadre-tete--menu">
                        FACTURÉ EFFECTIF <i class="fas fa-info-circle" title="Dossiers facturés"></i>
                    </div>
                    <div class="cadre-content">
                        <div class="kpis">
                            <div class="kpi">
                                <label>Total facturé</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    :currency="$compte.societe_devise"
                                    :empty-value="1"
                                    :value="indicateurs.total_facture"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                            <div class="kpi">
                                <label>Total encaissé</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    :currency="$compte.societe_devise"
                                    :empty-value="1"
                                    :value="indicateurs.total_encaisse"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>

                            <!--<div class="kpi">
                                <label>Total facturé</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    currency="%"
                                    :empty-value="1"
                                    :value="effectif.taux_transformation"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>-->
                        </div>
                        <!-- Comme demandé par Anthony, je laisse le code commenté
                        <div class="cadre-tete cadre-tete--menu" v-if="typeof indicateurs.total_facture_libre !== 'undefined'">
                            FACTURES AUTRES
                        </div>
                        <div class="kpis" v-if="typeof indicateurs.total_facture_libre !== 'undefined'">
                            <div class="kpi">
                                <label>Total facturé</label>
                                <vue-numeric
                                        thousand-separator=" "
                                        :currency="$compte.societe_devise"
                                        :empty-value="1"
                                        :value="indicateurs.total_facture_libre"
                                        :read-only="true"
                                        currency-symbol-position="suffix"
                                        read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                            <div class="kpi">
                                <label>Total encaissé</label>
                                <vue-numeric
                                        thousand-separator=" "
                                        :currency="$compte.societe_devise"
                                        :empty-value="1"
                                        :value="indicateurs.total_encaisse_libre"
                                        :read-only="true"
                                        currency-symbol-position="suffix"
                                        read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                        </div> -->
                    </div>
                    <div class="cadre-pied">
                        <router-link
                            tag="button"
                            class="btn btn-bordered btn-large"
                            :to="{name: 'DossiersFactures', query: { dateDebut: this.dateDebut, dateFin: this.dateFin }}">
                            Dossiers facturés
                        </router-link>
                        <router-link
                            tag="button"
                            class="btn btn-bordered btn-large"
                            :to="{name: 'DossiersEncaisses', query: { dateDebut: this.dateDebut, dateFin: this.dateFin }}">
                            Dossiers encaissés
                        </router-link>
                    </div>
                </div>

                <div class="cadre background-white">
                    <div class="cadre-tete cadre-tete--menu">
                        FACTURÉ PRÉVISIONNELS <i class="fas fa-info-circle" title="Dossiers entre Rendez-vous banque et Facturé"></i>
                    </div>
                    <div class="cadre-content">
                        <div class="kpis">
                            <div class="kpi">
                                <label>Total prévisionnel</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    :currency="$compte.societe_devise"
                                    :empty-value="0"
                                    :value="indicateurs.total_previsionnel"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                        </div>
                    </div>
                    <div class="cadre-pied">
                        <router-link
                            tag="button"
                            class="btn btn-bordered btn-large"
                            :to="{name: 'DossiersPrevisionnels', query: { dateDebut: this.dateDebut, dateFin: this.dateFin }}">
                            Dossiers prévisionnels
                        </router-link>
                    </div>
                </div>

                <div class="cadre background-white cadre--encours">
                    <div class="cadre-tete cadre-tete--menu ">
                        EN COURS <i class="fas fa-info-circle" title="Dossiers avant Rendez-vous banque"></i>
                    </div>
                    <div class="cadre-content">
                        <div class="kpis">
                            <div class="kpi">
                                <label>Total potentiel</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    :currency="$compte.societe_devise"
                                    :empty-value="0"
                                    :value="indicateurs.total_potentiel"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                            <div class="kpi">
                                <label>Dossiers</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    currency=""
                                    :empty-value="0"
                                    :value="indicateurs.dossiers_en_cours"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                            <div class="kpi">
                                <label>Dossiers en alerte</label>
                                <vue-numeric
                                    thousand-separator=" "
                                    currency=""
                                    :empty-value="0"
                                    :value="indicateurs.dossiers_en_alertes"
                                    :read-only="true"
                                    currency-symbol-position="suffix"
                                    read-only-class="kpi-value"
                                ></vue-numeric>
                            </div>
                        </div>
                    </div>
                    <div class="cadre-pied">
                        <router-link
                            tag="button"
                            class="btn btn-bordered btn-large"
                            :to="{name: 'DossiersEnCours', query: { dateDebut: this.dateDebut, dateFin: this.dateFin }}">
                            Dossiers en cours
                        </router-link>
                        <router-link
                            tag="button"
                            class="btn btn-bordered btn-large"
                            :to="{name: 'DossiersEnAlerte', query: { dateDebut: this.dateDebut, dateFin: this.dateFin }}">
                            Dossiers en alerte
                        </router-link> &nbsp;
                    </div>
                </div>
            </div>
        </div>
        <div class="chargement" v-if="chargement">
            <div>
                <b> Chargement en cours... </b>
            </div>
        </div>
    </div>
</template>

<script>

  import api from '@/api'
  import dayjs from 'dayjs'

  export default {
    name: 'TableauDeBord',
    data() {
      return {
        indicateurs: {},
        dateDebut: dayjs().startOf('year').format('YYYY-MM-DD'),
        dateFin: dayjs().format('YYYY-MM-DD')
      }
    },
    created() {
      this.chargement=true
      const {dateDebut, dateFin} =  this.$route.query || {}
      this.dateDebut = dateDebut ? dateDebut : this.dateDebut
      this.dateFin = dateFin ? dateFin : this.dateFin

      api.tableauBordCharger({
        debut: this.dateDebut,
        fin: this.dateFin
      }).then((d) => {
        this.indicateurs = d.data
        this.chargement = false
      })
    },
    methods: {
      filtrer(data) {
        this.indicateurs = data
        this.dateDebut = data.debut
        this.dateFin = data.fin
      }
    }
  }
</script>

<style lang="scss" scoped>

    .cadre {
        height: auto !important;
    }

    .suivis-container {
        display: flex;

        .cadre {
            width: 33%;
            height: 300px;
            margin-top: 20px;

            &:not(:last-child) {
                margin-right: 20px;
            }

            .cadre-pied {
                height: auto !important;
                min-height: 60px;

                .btn:not(:last-child) {
                    margin-right: 15px;
                }
            }

            @media only screen and (max-width: 870px) {
                width: 100% !important;
                margin-right: 0 !important;
            }
        }

        @media only screen and (max-width: 870px) {
            flex-wrap: wrap;
        }

        .cadre.cadre--encours {
            min-width: 45%;
        }

    }

    .cadre-content {
        padding: 10px !important;
    }

    .entete {
        font-weight: 100;
        h1 {
            font-weight: 100;
            margin: 0;
            padding-bottom: 15px;
        }

        p {
            font-size: .8rem;
            padding-bottom: 15px;
        }
    }

    .kpis {
        display: flex;

        label {
            font-weight: 100;
        }

        .kpi {
            flex: 1;
            //text-align: center;
            padding: 15px;
        }

        .kpi-value {
            font-size: 1.2rem;
        }
    }

    .entete-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .intervenants {
            margin-bottom: 10px;
            @media only screen and (max-width: 768px){
                width: 100%;
                max-width: inherit !important;
            }
        }

        .btn {
            height: 38px;
        }

        .filters {
            margin-right: 0;
            margin-left: auto;
        }
    }
</style>
