<template>
    <div>
        <div class="cadre kpis">
            <div class="cadre-content">
                <div class="kpi">
                    <label>Total encaissé</label>
                    <vue-numeric
                        thousand-separator=" "
                        :currency="$compte.societe_devise"
                        :empty-value="1"
                        :value="indicateurs.total_encaisse"
                        :read-only="true"
                        currency-symbol-position="suffix"
                        read-only-class="kpi-value"
                    ></vue-numeric>
                </div>

                <div class="kpi">
                    <label>Total facturé</label>
                    <vue-numeric
                        thousand-separator=" "
                        :currency="$compte.societe_devise"
                        :empty-value="1"
                        :value="indicateurs.total_facture"
                        :read-only="true"
                        currency-symbol-position="suffix"
                        read-only-class="kpi-value"
                    ></vue-numeric>
                </div>

                <div class="kpi">
                    <label>Taux transformation</label>
                    <vue-numeric
                        thousand-separator=" "
                        currency="%"
                        :empty-value="1"
                        :value="indicateurs.taux_transformation * 100"
                        :read-only="true"
                        currency-symbol-position="suffix"
                        read-only-class="kpi-value"
                    ></vue-numeric>
                </div>

                <div class="kpi">
                    <label>Chiffre d'affaire prévisionnel</label>
                    <vue-numeric
                        thousand-separator=" "
                        :currency="$compte.societe_devise"
                        :empty-value="1"
                        :value="indicateurs.ca_prev"
                        :read-only="true"
                        currency-symbol-position="suffix"
                        read-only-class="kpi-value"
                    ></vue-numeric>
                </div>
            </div>
        </div>
        <div class="agences">
            <div class="cadre kpis"
                 v-for="agence in agences"
                 :key="agence.id"
            >
                <div class="cadre-content">
                    <div class="agence-nom">
                        {{agence.nom}}
                        <div>
                            <small>Agence</small>
                        </div>
                    </div>
                    <div>
                        <div class="kpi">
                            <label>Total encaissé</label>
                            <vue-numeric
                                thousand-separator=" "
                                :currency="$compte.societe_devise"
                                :empty-value="1"
                                :value="agence.total_encaisse"
                                :read-only="true"
                                currency-symbol-position="suffix"
                                read-only-class="kpi-value"
                            ></vue-numeric>
                        </div>

                        <div class="kpi">
                            <label>Total facturé</label>
                            <vue-numeric
                                thousand-separator=" "
                                :currency="$compte.societe_devise"
                                :empty-value="1"
                                :value="agence.total_facture"
                                :read-only="true"
                                currency-symbol-position="suffix"
                                read-only-class="kpi-value"
                            ></vue-numeric>
                        </div>

                        <div class="kpi">
                            <label>Taux transformation</label>
                            <vue-numeric
                                thousand-separator=" "
                                currency="%"
                                :empty-value="1"
                                :value="agence.taux_transformation * 100"
                                :read-only="true"
                                currency-symbol-position="suffix"
                                read-only-class="kpi-value"
                            ></vue-numeric>
                        </div>

                        <div class="kpi">
                            <label>Chiffre d'affaire prévisionnel</label>
                            <vue-numeric
                                thousand-separator=" "
                                :currency="$compte.societe_devise"
                                :empty-value="1"
                                :value="agence.ca_prev"
                                :read-only="true"
                                currency-symbol-position="suffix"
                                read-only-class="kpi-value"
                            ></vue-numeric>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'SuiviAgence',
    data() {
      return {
        indicateurs: {},
        agences: []
      }
    },
    created() {
      api.suiviParAgenceCharger().then(({ indicateurs, agences }) => {
        this.indicateurs = indicateurs
        this.agences = agences
      })
    }
  }
</script>

<style lang="scss" scoped>

    .cadre {
        height: auto;
    }

    .kpis {
        label {
            font-weight: 100;
        }

        .cadre-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
        }

        .kpi {
            text-align: center;
            @media only screen and (max-width: 850px) {
                width: 45%;
                padding: 10px;
            }

            @media only screen and (max-width: 650px) {
                width: 100%;
                padding: 10px;
            }
        }

        .kpi-value {
            font-size: 1.2rem;
        }
    }

    .agences {
        padding-top: 50px;

        .agence-nom {
            width: 125px;
            text-align: center;
            text-transform: uppercase;

            small {
                color: gray;
                text-transform: none;
            }
        }

        .agence-nom + div {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 125px);
            justify-content: space-around;
        }
    }

</style>
