var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"lien-retour",attrs:{"to":{name: 'TableauDeBord'}}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Retour vue d'ensemble\n    ")]),_c('div',{staticClass:"cadre-tete cadre-tete--menu"},[_vm._v("\n        SUIVI PAR COLLABORATEURS\n\n        "),_c('filters',{attrs:{"filtrer":_vm.filtrer,"api":'suiviParIndividuCharger',"actions":['export'],"defaultData":{dateDebut: _vm.dateDebut, dateFin: _vm.dateFin},"facturesIndependantes":true}})],1),_c('div',{staticClass:"cadre-content background-gray"},[_c('div',[_c('div',{staticClass:"individus"},_vm._l((_vm.personnes),function(personne){return _c('div',{key:personne.id,staticClass:"cadre kpis"},[_c('div',{staticClass:"cadre-content"},[_c('div',{staticClass:"individu-nom"},[_c('div',[_vm._v("\n                                "+_vm._s(personne.nom)+"\n                            ")])]),_c('div',[_c('div',{staticClass:"kpi"},[_c('router-link',{attrs:{"tag":"div","to":{name: 'DossiersEncaisses', query: {
                                      intervenant: personne.id,
                                      dateDebut: _vm.dateDebut,
                                      dateFin: _vm.dateFin
                                    }}}},[_c('label',[_vm._v("Total encaissé")]),_vm._v("\n                                    "+_vm._s(personne.total_encaisse)+" "+_vm._s(_vm.$compte.societe_devise)+"\n                                    ")])],1),_c('div',{staticClass:"kpi"},[_c('router-link',{attrs:{"tag":"div","to":{name: 'DossiersFactures', query: {
                                      intervenant: personne.id,
                                      dateDebut: _vm.dateDebut,
                                      dateFin: _vm.dateFin
                                    }}}},[_c('label',[_vm._v("Total facturé")]),_vm._v("\n                                    "+_vm._s(personne.total_facture)+" "+_vm._s(_vm.$compte.societe_devise)+"\n                                    ")])],1),_c('div',{staticClass:"kpi"},[_c('router-link',{attrs:{"tag":"div","to":{name: 'DossiersPrevisionnels', query: {
                                      intervenant: personne.id,
                                      dateDebut: _vm.dateDebut,
                                      dateFin: _vm.dateFin
                                    }}}},[_c('label',[_vm._v("Total prévisionnel")]),_vm._v("\n                                    "+_vm._s(personne.total_previsionnel)+" "+_vm._s(_vm.$compte.societe_devise)+"\n                                ")])],1),_c('div',{staticClass:"kpi"},[_c('router-link',{attrs:{"tag":"div","to":{name: 'DossiersEnCours', query: {
                                      intervenant: personne.id,
                                      dateDebut: _vm.dateDebut,
                                      dateFin: _vm.dateFin
                                    }}}},[_c('label',[_vm._v("Total potentiel")]),_vm._v("\n                                    "+_vm._s(personne.total_potentiel)+" "+_vm._s(_vm.$compte.societe_devise)+"\n                                ")])],1)])])])}),0)])]),(_vm.chargement)?_c('div',{staticClass:"chargement"},[_vm._m(0)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b',[_vm._v(" Chargement en cours... ")])])}]

export { render, staticRenderFns }